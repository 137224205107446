import React from 'react'
import Box from '@mui/material/Box'

const Divider = () => {
  return (
    <Box sx={{
      width: 168,
      height: 6,
      backgroundColor: '#FFC5FF',
      marginBottom: '20px',
    }}>&nbsp;</Box>
  )
}

export default Divider