import React from 'react'

import Box from '@mui/material/Box'

import Typography from './Typography';

const Quote = (props) => {
  const { content } = props;
  return (
  <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
    <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
      <Box sx={{ fontSize: {xs: 120, sm: 100},fontFamily: 'Arial', marginRight: '30px', alignSelf: 'flex-start', marginTop: {xs: '-50px', sm: '0'}}}>&#8220;</Box>
      <Box sx={{ alignSelf: 'center', justifyContent: 'space-between' }}>
        <Typography type='heading' preset={2}>{content}</Typography>
      </Box>
    </Box>
  </Box>
  )
}


export default Quote