import React, { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiDivider from '@mui/material/Divider';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Button from '../components/Button';
import Divider from '../components/Divider';
import Card from '../components/Card';
import Testimonials from '../components/Testimonials';
import ContactForm from '../components/ContactForm';

import graphicClinicians from '../images/graphic_clinicians.svg';
import graphicWorkplaces from '../images/graphic_workplaces.svg';
import graphicClinicalPros from '../images/graphic_clinicalpros.svg';
import graphicMembers from '../images/graphic_members.svg';
import graphicStaff from '../images/graphic_staff.svg';
import graphicStudents from '../images/graphic_student.svg';

import shield from '../images/icon-shield.svg';
import microscope from '../images/icon-microscope.svg';
import networkmap from '../images/icon-networkmap.svg';
import user from '../images/icon-user.svg';
import direction from '../images/icon-direction.svg';
import graph from '../images/icon-graph.svg';
import { StaticImage } from 'gatsby-plugin-image';

// markup
const IndexPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const headerRef = useRef();

  return (
    <Page title='Home' headerRef={headerRef}>
      {/* Hero */}
      <Section theme='primary'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <StaticImage height={472.69} src='../../static/images/intro_phone.png' alt='Phone showing the Innowell App Dashboard'/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Open Up To Better Mental Health Care</Typography>
            <Typography type='paragraph' preset={2}>Innowell provides research-validated digital tools and personalised insights to connect your people to the support they need when they need it. Everyone deserves to be mentally healthy, and you deserve a mental health care platform that works. </Typography>
            <Button theme='primary' branded onClick={() => headerRef.current.triggerBookNow()}>Book a demo</Button>
          </Grid>
        </Grid>
      </Section>

      {/* Brands */}
      <Section wide variant='narrow'>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
          <figure>
            <StaticImage src='../../static/images/usyd.png' alt='Logo - University Of Sydney'/>
          </figure>
          <figure>
            <StaticImage src='../../static/images/headspace.png' alt='Logo - Headspace'/>
          </figure>
          <figure>
            <StaticImage src='../../static/images/ahs.png' alt='Logo - Alberta Health Services'/>
          </figure>
          <figure>
            <StaticImage src='../../static/images/CMHAlogo.png' alt='Logo - Canadian Mental Health Association'/>
          </figure>
          <figure>
            <StaticImage src='../../static/images/LHSClogo.png' alt='Logo - London Health Sciences Centre'/>
          </figure>
          <figure>
            <StaticImage src='../../static/images/futurebrandlogo.png' alt='Logo - FutureBrand'/>
          </figure>
        </Box>
        <MuiDivider variant='middle'/>
      </Section>

      {/* Big Cards */}
      <Section>
        <Grid container rowSpacing={4} columnSpacing={{ sm: 2, md: 12 }}>
          <Grid item xs={12} sm={6}>
            <Card
              center
              variant='tall'
              theme='daybreak'
              action={<Button theme='secondary' href="/your-students">Learn more</Button>}>
              <img style={{marginBottom: '64px', height: '164px'}} src={graphicStudents} alt='Graphic for Students'/>
              <Typography type='heading' preset={3}>Your students</Typography>
              <Typography type='paragraph' preset={2}>Our digital reporting tools and curated content will help you understand the needs of your student body and provide quicker, sustainable support when and how they need it. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              center
              variant='tall'
              theme='daybreak'
              action={<Button theme='secondary' href="/your-staff">Learn more</Button>}>
              <img style={{marginBottom: '64px', height: '164px'}}  src={graphicStaff} alt='Graphic for Staff'/>
              <Typography type='heading' preset={3}>Your staff</Typography>
              <Typography type='paragraph' preset={2}>Healthy people make healthy companies. Innowell understands this better than most, so we’ve built easy to use tools that empower your people to best take care of themselves, while providing insights into staff wellbeing. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              center
              variant='tall'
              theme='daybreak'
              action={<Button theme='secondary' href="/your-members">Learn more</Button>}>
              <img style={{marginBottom: '64px', height: '164px'}}  src={graphicMembers} alt='Graphic for Members'/>
              <Typography type='heading' preset={3}>Your members</Typography>
              <Typography type='paragraph' preset={2}>Innowell understands your members expect support, and that’s what we provide. Wholistic, intuitive, support in self-care and wellbeing. Our research-backed platform keys into individual needs and provides tailored mental health care tools and resources.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              center
              variant='tall'
              theme='daybreak'
              action={<Button theme='secondary' href="/your-clinical-professionals">Learn more</Button>}>
              <img style={{marginBottom: '64px', height: '164px'}}  src={graphicClinicalPros} alt='Graphic for Clinical Professionals'/>
              <Typography type='heading' preset={3}>Your clinical professionals</Typography>
              <Typography type='paragraph' preset={2}>Reduced wait times, transparent circle-of-care, and more engaged patients. Innowell knows what clinical professionals need to better support their patients and we’ve got the platform to provide that support.</Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* Mental Health for Clinicians */}
      <Section theme='primary' variant='wide'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='clinician graphic' src={graphicClinicians}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Mental Health Care In Clinics</Typography>
            <Typography type='paragraph' preset={2}>Built for patients, practitioners, and administrators​ Innowell works with you preventatively and post-discharge to provide comprehensive clinical coverage of mental health domains within patient circles-of-care. </Typography>
            <Divider/>
            <Typography type='paragraph' preset={2}>Each year only 50% of people who need clinical mental health support will get it. </Typography>

            <Button theme='secondary' href="/for-clinicians">Learn more</Button>
          </Grid>
        </Grid>
      </Section>

      {/* Mental Health for Workplaces */}
      <Section theme='primary' variant='wide'>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicWorkplaces}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Mental Health Support in Workplaces</Typography>
            <Typography type='paragraph' preset={2}>Our workplace platform provides detailed, live insights into your company’s mental health posture and the needs of your employees, all while protecting their privacy and recommending the tools and habits needed to keep your people well and grow a stronger ‘mental health first’ culture. </Typography>
            <Divider/>
            <Typography type='paragraph' preset={2}>75% of employees report experiencing burnout at work due to poor mental health. </Typography>

            <Button theme='secondary' href="/for-workplaces">Learn more</Button>
          </Grid>
        </Grid>
      </Section>

      {/* Values */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card center>
              <img src={user} alt='Right Care icon'/>
              <Typography type='heading' preset={3}>Right care</Typography>
              <Typography type='paragraph' preset={2}>Enables self-management as well as clinical support, as required. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card center>
              <img src={direction} alt='End-to-end icon'/>
              <Typography type='heading' preset={3}>End-to-end</Typography>
              <Typography type='paragraph' preset={2}>Supports every stage of mental fitness. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card center>
              <img src={graph} alt='Data-driven icon'/>
              <Typography type='heading' preset={3}>Data-driven</Typography>
              <Typography type='paragraph' preset={2}>Personalised insights supported by aggregated trends. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card center>
              <img src={shield} alt='Secure and Private icon'/>
              <Typography type='heading' preset={3}>Secure and private</Typography>
              <Typography type='paragraph' preset={2}>We never share data without your wish.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card center>
              <img src={microscope} alt='Research Validated icon'/>
              <Typography type='heading' preset={3}>Research validated</Typography>
              <Typography type='paragraph' preset={2}>More than 10 years of research and over a dozen research trials</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card center>
              <img src={networkmap} alt='World-leading icon'/>
              <Typography type='heading' preset={3}>World-leading</Typography>
              <Typography type='paragraph' preset={2}>World leading experts providing best-in-class resources. </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* Library */}
      <Section wide theme='primary' title='Library'>
        <Grid container columnSpacing={{ md: 12 }}>
          <Grid item xs={12} md={4}>
            <Card
              center
              variant='tall'
              figure={<StaticImage src='../images/image_cognitive.png' alt='Cognitive Reappraisal: What Is Thought Reframing?'/>}
              action={<Button theme='primary' href="/library/articles/cognitive-reappraisal">Read</Button>}
            >
              <Typography type='heading' preset={2}>
                Cognitive Reappraisal: What Is Thought Reframing?
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              center
              variant='tall'
              figure={<StaticImage src='../images/image_minding.png' alt='Burnout - Minding Your Mind Podcast'/>}
              action={<Button theme='primary' href="/library/podcasts/burnout-minding-your-mind/">Listen</Button>}
            >
              <Typography type='heading' preset={2}>
                Burnout - Minding Your Mind Podcast
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              center
              variant='tall'
              figure={<StaticImage src='../images/image_emotions.png' alt='Emotions, Feelings, and Moods: What&amp;s the difference?'/>}
              action={<Button theme='primary' href="/library/articles/emotions-feelings-and-moods">Read</Button>}
            >
              <Typography type='heading' preset={2}>
                Emotions, Feelings, and Moods: What's the difference?
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>

      {/* Testimonials */}
      <Section>
        <Testimonials/>
      </Section>

      {/* Book a demo */}
      <Section title='Book a demo' wide sparkly>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography>
              Want to explore how the Innowell platform can improve your organisation’s mental fitness, enable you to deliver enhanced clinical care, or help strengthen your own mental wellbeing?
            </Typography>
            <Typography>
              Send us your details and we'll be in touch soon.
            </Typography>
            <Typography>
              Please note, we are not a crisis service.
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <ContactForm/>
          </Grid>
        </Grid>
      </Section>
    </Page>
  )
};

export default IndexPage;
