import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Quote from './Quote';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  dot: {
    border: '1px solid #040068',
    backgroundColor: 'transparent',
  },
  dotActive: {
    backgroundColor: "#040068",
  },
  root: {
    justifyContent:'center',
    backgroundColor: 'transparent',
  },
  dots: {
    width: '10%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xl')]: {
      width: '5%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '30%',
    },
  },
}));

const steps =[
  {
    quote: "[Innowell] complement[s] existing mental health interventions by offering support to people wherever they are – when they need help the most.",
    author: "- Professor Ian Hickie",
  },
  {
    quote: "[Innowell] does this within an environment that promotes active and ongoing partnerships with health professionals, while protecting personal privacy.",
    author: "- University of Sydney",
  },
  {
    quote: "It can be 2:00 AM and I can sit there and add something, and be reassured that it's not something iffy, that it's mental ill health, and [Innowell] will direct me to a form of care straight away.",
    author: "- Samuel",
  }
]

const Quotes = () => {
  const theme = useTheme();
  const classes = useStyles();
  const maxSteps = steps.length;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return(
    <Box className={classes.container}>
      <Quote content={steps[activeStep].quote} />
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        classes={{
          dot: classes.dot,
          dotActive: classes.dotActive,
          root: classes.root,
          dots: classes.dots,
        }}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps -1}>

            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}

          </Button>
        }
      />
    </Box>
  )
};

export default Quotes;
